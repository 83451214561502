import React from "react"
import Layout from "../../components/layout/layout.js"
import { css } from "@emotion/react"
import Seo from "../../components/seo"
import BlogThumbnail from '../../components/blog-thumbnail/blog-thumbnail'
import { StaticImage } from "gatsby-plugin-image"

const DLIndexPage = () => {
	return (
    <Layout>
		<Seo title="Deep Learning" 
			keywords={['Image Segmentation', 'Object Detection','Ganiyu AJ Ibraheem']}/>
        <div className="hidden md:block" css={css`margin-top: 2rem;`}>
            <div className="grid md:grid-cols-2 justify-center">
                <div className="md:max-w-md">
                    <StaticImage src="./../../images/geordanna-cordero-fields-762610-unsplash.jpg" alt="" />
                </div>
                <div className="md:max-w-md text-left px-4 max-w-prose md:text-sm" css={css`
                    margin-top: 3rem;
                `}>
                    <h1>Deep Learning</h1>
                    <small className="text-muted">EDITORS</small>
                    <small className="" css={css`
                        display: block;
                    `}>AJ Ibraheem</small>
                </div>
            </div>
        </div>

        <div className="grid md:grid-rows-1" css={css`background-color: rgba(236, 240, 241, 1);`}>
            <div className="grid md:grid-cols-3 md:gap-4" css={css`margin: 2rem; padding: 1rem;`}>
                <div className="md:max-w-md">
                    <BlogThumbnail 
                        title="Object Detection"
                        blog_url="/blogs/deep-learning/object-detection/"
                        body="Using deep convolutional networks for localising objects in images">
                            <StaticImage 
                                src="./../../images/geordanna-cordero-fields-762624-unsplash.jpg" 
                                alt="Thumbnail for object detection category"
                                className="thumbnail-image" />
                    </BlogThumbnail>
                </div>

                <div className="md:max-w-md">
                    <BlogThumbnail 
                        title="Multiple Object Tracking"
                        blog_url="/blogs/deep-learning/multiple-object-tracking/"
                        body="Tracking objects across images">
                            <StaticImage 
                                src="https://cdn.araintelligence.com/images/cover-art/birmingham-museums-trust-KfRUve5NtO8-unsplash.jpg" 
                                alt="Thumbnail for multiple object tracking category"
                                layout="constrained"
                                className="thumbnail-image"
                                />
                    </BlogThumbnail>
                </div>

                <div className="md:max-w-md">
                    <BlogThumbnail 
                        title="Foundational Concepts"
                        blog_url="/blogs/deep-learning/concepts/"
                        body="Building blocks for Deep Learning architectures">
                            <StaticImage 
                                src="https://cdn.araintelligence.com/images/cover-art/rodion-kutsaev-6W8H4puOJB0-unsplash.jpg" 
                                alt="Thumbnail for foundational concepts category"
                                layout="constrained"
                                className="thumbnail-image"
                                />
                    </BlogThumbnail>
                </div>
            </div>
        </div>
	</Layout>)
}

export default DLIndexPage